// ** Initial State
const initialState = {
  allData: [],
  data: [],
  savedData: [],
  dataVO: [],
  dataSPEC: [],
  dataST: [],
  dataVW: [],
  dataAMC: [],
  dataINS: [],
  dataCAL: [],
  dataPM: [],
  dataStatus: [],
  dataQA: [],
  dataLocation: [],
  total: 1,
  params: {},
  selectedAsset: null,
  publicAsset: null,
  selectedAssetVendor: null,
  selectedAssetSpecification: [],
  selectedAssetAssignedBranchDepartmentLog: [],
  selectedAssetAssignedLocationLog: [],
  selectedAssetVendorOwnedDocument: [],
  selectedAssetAMCDocument: [],
  selectedAssetINSDocument: [],
  selectedAssetPMDocument: [],
  selectedAssetQADocument: [],
  selectedAssetCalibrationDocument: [],
  selectedAssetVendorOwnedWarrantyDocument: [],
  selectedAssetAMC: [],
  selectedAssetINS: [],
  selectedAssetPM: [],
  selectedAssetLocation: [],
  selectedAssetQA: [],
  selectedAssetOpenedServiceTicket: [],
  selectedAssetClosedServiceTicket: [],
  selectedServiceTicket: [],
  selectedServiceTicketFeedback: [],
  selectedAssetCalibration: [],
  selectedAssetHistory: [],
  selectedAssetHistorySummary: [],
  selectedAssetPMResponsible: null,
  selectedAssetQAResponsible: null,
  selectedAssetCalibrationResponsible: null,
  selectedAssetIncidentInspection: null, 
  selectedAssetStatus: null,
  assetStatusAll: null,
  selectedAssetState: null,
  selectedAssetLiveLocation: null,
  selectedAssetVendorOwnedDepreciation: [],
  selectedAssetVendorOwnedDepreciationDimBal: [],
  selectedAssetVendorOwnedDepreciationSLV: [],
  selectedAssetVendorOwnedDepreciationSummary: [],
  selectedAssetImage: null,
  selectedAssetDocument: []

}

const AssetReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_ASSET':
      return { ...state, allData: action.data }
    case 'GET_DATA_ASSET':
      return {
        ...state,
        data: action.data
      }
      case 'GET_DATA_SAVED_ASSET':
        return {
          ...state,
          savedData: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_DATA_ASSET_VENDOR_OWNED':
      return {
        ...state,
        dataVO: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'GET_DATA_ASSET_LOCATION':
      return {
        ...state,
        dataLocation: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_VENDOR_WARRANTY':
      return {
        ...state,
        dataVW: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_AMC':
      return {
        ...state,
        dataAMC: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_INS':
      return {
        ...state,
        dataINS: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_CALIBRATION':
      return {
        ...state,
        dataCAL: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ASSET_PM':
    return {
      ...state,
      dataPM: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_ASSET_STATUS':
    return {
      ...state,
      dataStatus: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_ASSET_SPECIFICATION':
    return {
      ...state,
      dataSPEC: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_ASSET_SERVICE_TICKET':
    return {
      ...state,
      dataST: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_ASSET_QA':
    return {
      ...state,
      dataQA: action.data,
      total: action.totalPages,
      params: action.params
    }        
    case 'GET_ASSET':
      return { ...state, selectedAsset: action.selectedAsset }
    case 'GET_ASSET_PARAMETER':
      return { ...state, publicAsset: action.publicAsset }
    case 'GET_ASSET_VENDOR':
      return { ...state, selectedAssetVendor: action.selectedAssetVendor }
      case 'GET_ASSET_VENDOR_OWNED_DOCUMENT':
      return { ...state, selectedAssetVendorOwnedDocument: action.selectedAssetVendorOwnedDocument }
    case 'GET_ASSET_AMC_DOCUMENT':
      return { ...state, selectedAssetAMCDocument: action.selectedAssetAMCDocument }
    case 'GET_ASSET_INS_DOCUMENT':
      return { ...state, selectedAssetINSDocument: action.selectedAssetINSDocument }
    case 'GET_ASSET_PM_DOCUMENT':
      return { ...state, selectedAssetPMDocument: action.selectedAssetPMDocument }
    case 'GET_ASSET_QA_DOCUMENT':
      return { ...state, selectedAssetQADocument: action.selectedAssetQADocument }
    case 'GET_ASSET_CALIBRATION_DOCUMENT':
      return { ...state, selectedAssetCalibrationDocument: action.selectedAssetCalibrationDocument }
    case 'GET_ASSET_VENDOR_OWNED_WARRANTY_DOCUMENT':
      return { ...state, selectedAssetVendorOwnedWarrantyDocument: action.selectedAssetVendorOwnedWarrantyDocument }
    case 'GET_ASSET_AMC':
      return { ...state, selectedAssetAMC: action.selectedAssetAMC }
    case 'GET_ASSET_INS':
      return { ...state, selectedAssetINS: action.selectedAssetINS }
    case 'GET_ASSET_CALIBRATION':
      return { ...state, selectedAssetCalibration: action.selectedAssetCalibration }
    case 'GET_ASSET_PM':
      return { ...state, selectedAssetPM: action.selectedAssetPM }
    case 'GET_ASSET_LOCATION':
      return { ...state, selectedAssetLocation: action.selectedAssetLocation }
    case 'GET_ASSET_QA':
      return { ...state, selectedAssetQA: action.selectedAssetQA }
    case 'GET_SERVICE_TICKET':
      return { ...state, selectedServiceTicket: action.selectedServiceTicket }
    case 'GET_SERVICE_TICKET_FEEDBACK':
      return { ...state, selectedServiceTicketFeedback: action.data }
    case 'GET_ASSET_OPENED_SERVICE_TICKET':
      return { ...state, selectedAssetOpenedServiceTicket: action.selectedAssetOpenedServiceTicket }
    case 'GET_ASSET_CLOSED_SERVICE_TICKET':
      return { ...state, selectedAssetClosedServiceTicket: action.selectedAssetClosedServiceTicket }
    case 'GET_ASSET_CALIBRATION_RESPONSIBLE':
      return { ...state, selectedAssetCalibrationResponsible: action.selectedAssetCalibrationResponsible }
    case 'GET_ASSET_PM_RESPONSIBLE':
      return { ...state, selectedAssetPMResponsible: action.selectedAssetPMResponsible }
    case 'GET_ASSET_QA_RESPONSIBLE':
      return { ...state, selectedAssetQAResponsible: action.selectedAssetQAResponsible }
    
    case 'GET_ASSET_INCIDENT_INSPECTION':
      return { ...state, selectedAssetIncidentInspection: action.selectedAssetIncidentInspection }
    case 'GET_ASSET_STATUS':
      return { ...state, selectedAssetStatus: action.selectedAssetStatus }
    case 'GET_ASSET_STATUS_ALL':
      return { ...state, assetStatusAll: action.selectedAssetStatus }
    case 'GET_ASSET_STATE':
      return { ...state, selectedAssetState: action.selectedAssetState }
    case 'GET_ASSET_LIVE_LOCATION':
      return { ...state, selectedAssetLiveLocation: action.selectedAssetLiveLocation }
    case 'GET_ASSET_HISTORY':
      return { ...state, selectedAssetHistory: action.selectedAssetHistory }
    case 'GET_ASSET_HISTORY_SUMMARY':
      return { ...state, selectedAssetHistorySummary: action.selectedAssetHistorySummary }
    case 'GET_ASSET_DOCUMENT':
      return { ...state, selectedAssetDocument: action.selectedAssetDocument }
    case 'GET_ASSET_IMAGE':
      return { ...state, selectedAssetImage: action.selectedAssetImage }
    case 'GET_ASSET_VENDOR_OWNED_DEPRECIATION':
      return { ...state, selectedAssetVendorOwnedDepreciation: action.data }
    case 'GET_ASSET_VENDOR_OWNED_DEPRECIATION_DIMINISHING_BALANCE':
      return { ...state, selectedAssetVendorOwnedDepreciationDimBal: action.data }
    case 'GET_ASSET_VENDOR_OWNED_DEPRECIATION_STRAIGHT_LINE_VARIATION':
      return { ...state, selectedAssetVendorOwnedDepreciationSLV: action.data }
    case 'GET_ASSET_VENDOR_OWNED_DEPRECIATION_SUMMARY':
      return { ...state, selectedAssetVendorOwnedDepreciationSummary: action.data }
    
    case 'GET_ASSET_SPECIFICATION_BY_ASSET':
      return { ...state, selectedAssetSpecification: action.selectedAssetSpecification }
    
      case 'GET_ASSET_ASSIGNED_BBL_BY_ASSET':
      return { ...state, selectedAssetAssignedBranchDepartmentLog: action.selectedAssetAssignedBranchDepartmentLog }
      
      case 'GET_ASSET_ASSIGNED_LOC_BY_ASSET':
        return { ...state, selectedAssetAssignedLocationLog: action.selectedAssetAssignedLocationLog }
        
    case 'ASSETERROR':
      return { ...state, assetError: action.data }
    case "CLEAR_ALL":
        return initialState
    default:
      return { ...state }
  }
}
export default AssetReducer