// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedJobCompletionStatus: null
  }
  
  const OMJobCompletionStatusReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_JOB_COMPLETION_STATUS':
        return { ...state, allData: action.data }
      case 'GET_DATA_JOB_COMPLETION_STATUS':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_JOB_COMPLETION_STATUS':
        return { ...state, selectedJobCompletionStatus: action.selectedJobCompletionStatus }
      case 'JOBCOMPLETIONSTATUSERROR':
        return { ...state, jobCompletionStatusError: action.data }
      default:
        return { ...state }
    }
  }
  export default OMJobCompletionStatusReducer