// ** Initial State
const initialState = {
  allData: [],
  allGoodServiceReceiptConditionTypeData: [],
  data: [],
  total: 1,
  params: {},
  selectedGoodServiceReceiptCondition: null
}

const OMGoodServiceReceiptConditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_GOOD_SERVICE_RECEIPT_CONDITION':
      return { ...state, allData: action.data }
    case 'GET_ALL_GOOD_SERVICE_RECEIPT_CONDITION_TYPE':
      return { ...state, allGoodServiceReceiptConditionTypeData: action.data }
    case 'GET_DATA_GOOD_SERVICE_RECEIPT_CONDITION':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_GOOD_SERVICE_RECEIPT_CONDITION':
      return { ...state, selectedGoodServiceReceiptCondition: action.selectedGoodServiceReceiptCondition }
    case 'GOODSERVICERECEIPTCONDITIONERROR':
      return { ...state, goodServiceReceiptConditionError: action.data }
    default:
      return { ...state }
  }
}
export default OMGoodServiceReceiptConditionReducer