// ** Initial State
const initialState = {
  allData: [],
  allApprovalMatrixTypeData: [],
  data: [],
  total: 1,
  params: {},
  selectedApprovalMatrix: null,
  addNew: null
}

const OMApprovalMatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_APPROVAL_MATRIX':
      return { ...state, allData: action.data !== null ? action.data : [] }
    case 'ADD_NEW_APPROVAL_MATRIX':
      return {
        ...state,
        allData: [...state.allData, action.data]
      }
    case 'UPDATE_APPROVERS':
      return {
        ...state,
        allData: state.allData.map(item => (item.id === action.data.id ? {
          ...item,
          approvers: action.data.newApprovers,
          approvers_id: action.data.newApproversId
        } : item)
        )
      }

    case 'GET_ALL_APPROVAL_MATRIX_TYPE':
      return { ...state, allApprovalMatrixTypeData: action.data }
    case 'GET_DATA_APPROVAL_MATRIX':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_APPROVAL_MATRIX':
      return { ...state, selectedApprovalMatrix: action.selectedApprovalMatrix }
    case 'APPROVALMATRIXERROR':
      return { ...state, approvalMatrixError: action.data }
    default:
      return { ...state }
  }
}
export default OMApprovalMatrixReducer