// ** Initial State
const initialState = {
  allData: [],
  allFFData: [],
  data: [],
  dataST: [],
  total: 1,
  params: {},
  clientRoomFittingFurniture: [],
  clientRoomAssignedDepartment: [],
  selectedClientRoomOpenedServiceTicket: [],
  selectedClientRoomSpecification: [],
  dataRoomSpec: [],
  selectedClientRoom: null,
  selectedClientRoomStatus: null,
  clientRoomStatusAll: null

}

const ClientRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_ROOM':
      return { ...state, allData: action.data }
    case 'GET_ALL_CLIENT_ROOM_FITTING_FURNITURE':
      return { ...state, allFFData: action.data }
    case 'GET_ALL_IN_ASSET_CLIENT_ROOM':
      return { ...state, allData: action.data }

    case 'GET_DATA_CLIENT_ROOM':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_ROOM_SERVICE_TICKET':
      return {
        ...state,
        dataST: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'GET_CLIENT_ROOM':
      return { ...state, selectedClientRoom: action.selectedClientRoom }
    case 'GET_CLIENT_ROOM_DOCUMENT':
      return { ...state, selectedClientRoomDocument: action.selectedClientRoomDocument }
    case 'GET_CLIENT_ROOM_IMAGE':
      return { ...state, selectedClientRoomImage: action.selectedClientRoomImage }
    case 'GET_CLIENT_ROOM_FITTING_FURNITURE':
      return { ...state, clientRoomFittingFurniture: action.clientRoomFittingFurniture }
    case 'GET_CLIENT_ROOM_ASSIGNED_DEPARTMENT':
      return { ...state, clientRoomAssignedDepartment: action.clientRoomAssignedDepartment }
    case 'GET_CLIENT_ROOM_OPENED_SERVICE_TICKET':
      return { ...state, selectedClientRoomOpenedServiceTicket: action.selectedClientRoomOpenedServiceTicket }
    case 'GET_CLIENT_ROOM_SPECIFICATION_BY_ROOM':
      return { ...state, selectedClientRoomSpecification: action.selectedClientRoomSpecification }
    case 'GET_DATA_CLIENT_ROOM_SPECIFICATION':
      return { ...state, dataRoomSpec: action.data }
    case 'GET_CLIENT_ROOM_STATUS':
      return { ...state, selectedClientRoomStatus: action.selectedClientRoomStatus }
    case 'GET_CLIENT_ROOM_STATUS_ALL':
      return { ...state, clientRoomStatusAll: action.selectedClientRoomStatus }
    case 'GET_DATA_CLIENT_ROOM_STATUS':
      return {
        ...state,
        dataStatus: action.data,
        total: action.totalPages,
        params: action.params
      }

    case "CLEAR_ALL":
      return initialState

    case 'CLIENTROOMERROR':
      return { ...state, clientRoomError: action.data }
    default:
      return { ...state }
  }
}
export default ClientRoomReducer