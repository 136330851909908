// ** Initial State
const initialState = {
  total: 1,
  params: {},
  data: [],
  allData: [],
  allJOData: [],
  allPYData: [],
  allINData: [],
  allAHData: [],
  allEMData: [],
  allVNData: [],

  selectedOrderManagement: null,
  allOrderManagementData: [],
  selectedOrderManagementDocument: [],
  selectedOrderManagementComment: [],
  selectedOrderManagementImage: null,

  selectedOMEM: null,
  selectedOMEMDoc: [],
  selectedOMEMItems: [],
  selectedOMEMImport: null,
  selectedOMEMItemsImport: [],
  selectedOMEMApproval: [],
  selectedOMApproval: null,
  OMEMHistory: [],
  selectedOMEMDocHistory: [],
  selectedOMEMItemsHistory: [],
  selectedOMEMApprovalHistory: [],

  allVNData: [],
  selectedOMVNChosenVendor: null,
  selectedOMVNQuote: null,
  selectedOMVNQuoteAwarded: null,
  selectedOMVNQuoteImport: null,
  selectedOMVNQuoteItemsImport: [],
  selectedOMVNQuoteDoc: [],
  selectedOMVNQuoteItems: [],
  selectedOMVNQuote: null,

  selectedOMJO: null,
  selectedOMJODoc: [],
  selectedOMJOItems: [],
  selectedOMJOApproval: [],
  selectedApproval: [],
  OMJOHistory: [],
  selectedOMJODocHistory: [],
  selectedOMJOItemsHistory: [],
  selectedOMJOApprovalHistory: [],

  selectedOMEMVN: [],
  projectNames: [],

  selectedOMINOrder: null,

  selectedOMIN: [],
  selectedOMINDoc: [],
  selectedOMINFNDoc: [],
  selectedOMINItems: [],
  selectedOMINPF: [],
  selectedOMINBS: [],
  selectedOMINItemReceipts: [],
  selectedOMINAllItemReceipts: [],
  selectedOMPYIN: [],
  selectedOMPY: [],
  selectedOMINModal: [],
  OMPY: [],
  selectedOMPYApproval: [],
  selectedOMPYFINALIN: [],
  selectedOMPYDoc: [],
  selectedOMINPY: [],
  selectedJS: [],
  selectedJSAll: []

}

const OrderManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDER_MANAGEMENT':
      return { ...state, selectedOrderManagement: action.selectedOrderManagement }
    case 'GET_ALL_ORDER_MANAGEMENT':
      return { ...state, allData: action.allData }
    case 'GET_OM_JS':
      return { ...state, selectedJS: action.selectedJS }
    case 'GET_OM_JS_ALL':
      return { ...state, selectedJSAll: action.selectedJS }
      case 'GET_ALL_EM':
        return { ...state, allEMData: action.allEMData }
        case 'GET_ALL_VN':
          return { ...state, allVNData: action.allVNData }
        case 'GET_ALL_JO':
      return { ...state, allJOData: action.allJOData }
    case 'GET_ALL_IN':
      return { ...state, allINData: action.allINData }
    case 'GET_ALL_PY':
      return { ...state, allPYData: action.allPYData }
    case 'GET_ALL_AH':
      return { ...state, allAHData: action.allAHData }

    case 'GET_ORDER_MANAGEMENT_DOCUMENT':
      return { ...state, selectedOrderManagementDocument: action.selectedOrderManagementDocument }
    case 'GET_ORDER_MANAGEMENT_COMMENT':
      return { ...state, selectedOrderManagementComment: action.selectedOrderManagementComment }
    case 'GET_ORDER_MANAGEMENT_IMAGE':
      return { ...state, selectedOrderManagementImage: action.selectedOrderManagementImage }

    case 'GET_OM_APPROVAL':
      return { ...state, selectedOMApproval: action.selectedOMApproval }
    case 'GET_ALL_OM_APPROVAL':
      return { ...state, selectedApproval: action.selectedApproval }
    case 'GET_OM_EM':
      return { ...state, selectedOMEM: action.selectedOMEM }
    case 'GET_OM_EM_APPROVAL':
      return { ...state, selectedOMEMApproval: action.selectedOMEMApproval }

    case 'GET_OM_EM_DOC':
      return { ...state, selectedOMEMDoc: action.selectedOMEMDoc }
    case 'GET_OM_EM_ITEMS':
      return { ...state, selectedOMEMItems: action.selectedOMEMItems }
    case 'ADD_NEW_OM_EM_ITEM':
      return {
        ...state,
        selectedOMEMItems: [...state.selectedOMEMItems, action.data]
      }
    case 'UPDATE_OM_EM_ITEM_BILLING_CYCLE':
      return {
        ...state,
        selectedOMEMItems: state.selectedOMEMItems.map(item => (item.id === action.data.id ? {
          ...item,
          omEMItemBillingCycle: action.data.omEMItemBillingCycle,
          omEMItemBillingCycleId: action.data.omEMItemBillingCycleId
        } : item)
        )
      }
    case 'UPDATE_OM_EM_ITEM_UNIT':
      return {
        ...state,
        selectedOMEMItems: state.selectedOMEMItems.map(item => (item.id === action.data.id ? {
          ...item,
          omEMItemUnit: action.data.omEMItemUnit,
          omEMItemUnitId: action.data.omEMItemUnitId
        } : item)
        )
      }
    case 'UPDATE_OM_EM_ITEM_TOTAL':
      return {
        ...state,
        selectedOMEMItems: state.selectedOMEMItems.map(item => (item.id === action.data.id ? {
          ...item,
          omEMItemRate: action.data.omEMItemRate,
          omEMItemQuantity: action.data.omEMItemQuantity,
          omEMItemOtherCost: action.data.omEMItemOtherCost,
          omEMItemTax: action.data.omEMItemTax,
          omEMItemTotalNetCost: action.data.omEMItemTotalNetCost,
          omEMItemTotal: action.data.omEMItemTotal,
          omEMItemGross: action.data.omEMItemGross
        } : item)
        )
      }
    case 'UPDATE_OM_EM_ITEM_OTHER_COST':
      return {
        ...state,
        selectedOMEMItems: state.selectedOMEMItems.map(item => (item.id === action.data.id ? {
          ...item,
          omEMItemOtherCostName: action.data.omEMItemOtherCostName,
          omEMItemOtherCost: action.data.omEMItemOtherCost,
          omEMItemTotal: Number(item.omEMItemTotalNetCost ? item.omEMItemTotalNetCost : 0) + Number(action.data.omEMItemOtherCost ? action.data.omEMItemOtherCost : 0),
          omEMItemGross: Number(item.omEMItemTotalNetCost ? item.omEMItemTotalNetCost : 0) + Number(action.data.omEMItemOtherCost ? action.data.omEMItemOtherCost : 0) + Number(item.omEMItemTax ? item.omEMItemTax : 0)
        } : item)
        )
      }
    case 'UPDATE_OM_EM_ITEM_TAX':
      return {
        ...state,
        selectedOMEMItems: state.selectedOMEMItems.map(item => (item.id === action.data.id ? {
          ...item,
          omEMItemTaxName: action.data.omEMItemTaxName,
          omEMItemTax: Number(item.omEMItemTotal ? item.omEMItemTotal : 0) * (Number(action.data.omEMItemTax) / 100),
          omEMItemTaxType: action.data.omEMItemTaxType,
          omEMItemTaxId: action.data.omEMItemTaxId,
          omEMItemGross: Number(item.omEMItemTotal ? item.omEMItemTotal : 0) + (Number(item.omEMItemTotal ? item.omEMItemTotal : 0) * (Number(action.data.omEMItemTax) / 100))
        } : item)
        )
      }

    case 'GET_ORDER_MANAGEMENT_DATA':
      return { ...state, allOrderManagementData: action.allOrderManagementData }

    case 'GET_OM_VN':
      return { ...state, allVNData: action.allVNData }
    case 'GET_OM_VN_QUOTE':
      return { ...state, selectedOMVNQuote: action.selectedOMVNQuote }
    case 'GET_OM_VN_QUOTE_AWARDED':
      return { ...state, selectedOMVNQuoteAwarded: action.selectedOMVNQuoteAwarded }
    case 'GET_OM_VN_CHOSEN_VENDOR':
      return { ...state, selectedOMVNChosenVendor: action.selectedOMVNChosenVendor }
    case 'GET_OM_VN_QUOTE_DOC':
      return { ...state, selectedOMVNQuoteDoc: action.selectedOMVNQuoteDoc }
    case 'GET_OM_VN_QUOTE_ITEMS':
      return { ...state, selectedOMVNQuoteItems: action.selectedOMVNQuoteItems }
    case 'ADD_NEW_OM_VN_QUOTE_ITEM':
      return {
        ...state,
        selectedOMVNQuoteItems: [...state.selectedOMVNQuoteItems, action.data]
      }
    case 'UPDATE_OM_VN_QUOTE_ITEM_BILLING_CYCLE':
      return {
        ...state,
        selectedOMVNQuoteItems: state.selectedOMVNQuoteItems.map(item => (item.id === action.data.id ? {
          ...item,
          omVNQuoteItemBillingCycle: action.data.omVNQuoteItemBillingCycle,
          omVNQuoteItemBillingCycleId: action.data.omVNQuoteItemBillingCycleId
        } : item)
        )
      }
    case 'UPDATE_OM_VN_QUOTE_ITEM_UNIT':
      return {
        ...state,
        selectedOMVNQuoteItems: state.selectedOMVNQuoteItems.map(item => (item.id === action.data.id ? {
          ...item,
          omVNQuoteItemUnit: action.data.omVNQuoteItemUnit,
          omVNQuoteItemUnitId: action.data.omVNQuoteItemUnitId
        } : item)
        )
      }
    case 'UPDATE_OM_VN_QUOTE_ITEM_TOTAL':
      return {
        ...state,
        selectedOMVNQuoteItems: state.selectedOMVNQuoteItems.map(item => (item.id === action.data.id ? {
          ...item,
          omVNQuoteItemRate: action.data.omVNQuoteItemRate,
          omVNQuoteItemQuantity: action.data.omVNQuoteItemQuantity,
          omVNQuoteItemOtherCost: action.data.omVNQuoteItemOtherCost,
          omVNQuoteItemTax: action.data.omVNQuoteItemTax,
          omVNQuoteItemTotalNetCost: action.data.omVNQuoteItemTotalNetCost,
          omVNQuoteItemTotal: action.data.omVNQuoteItemTotal,
          omVNQuoteItemGross: action.data.omVNQuoteItemGross
        } : item)
        )
      }
    case 'UPDATE_OM_VN_QUOTE_ITEM_OTHER_COST':
      return {
        ...state,
        selectedOMVNQuoteItems: state.selectedOMVNQuoteItems.map(item => (item.id === action.data.id ? {
          ...item,
          omVNQuoteItemOtherCostName: action.data.omVNQuoteItemOtherCostName,
          omVNQuoteItemOtherCost: action.data.omVNQuoteItemOtherCost,
          omVNQuoteItemTotal: Number(item.omVNQuoteItemTotalNetCost ? item.omVNQuoteItemTotalNetCost : 0) + Number(action.data.omVNQuoteItemOtherCost ? action.data.omVNQuoteItemOtherCost : 0),
          omVNQuoteItemGross: Number(item.omVNQuoteItemTotalNetCost ? item.omVNQuoteItemTotalNetCost : 0) + Number(action.data.omVNQuoteItemOtherCost ? action.data.omVNQuoteItemOtherCost : 0) + Number(item.omVNQuoteItemTax ? item.omVNQuoteItemTax : 0)
        } : item)
        )
      }
    case 'UPDATE_OM_VN_QUOTE_ITEM_TAX':
      return {
        ...state,
        selectedOMVNQuoteItems: state.selectedOMVNQuoteItems.map(item => (item.id === action.data.id ? {
          ...item,
          omVNQuoteItemTaxName: action.data.omVNQuoteItemTaxName,
          omVNQuoteItemTax: Number(item.omVNQuoteItemTotal ? item.omVNQuoteItemTotal : 0) * (Number(action.data.omVNQuoteItemTax) / 100),
          omVNQuoteItemTaxType: action.data.omVNQuoteItemTaxType,
          omVNQuoteItemTaxId: action.data.omVNQuoteItemTaxId,
          omVNQuoteItemGross: Number(item.omVNQuoteItemTotal ? item.omVNQuoteItemTotal : 0) + (Number(item.omVNQuoteItemTotal ? item.omVNQuoteItemTotal : 0) * (Number(action.data.omVNQuoteItemTax) / 100))
        } : item)
        )
      }


    case 'GET_OM_JO':
      return { ...state, selectedOMJO: action.selectedOMJO }
    case 'GET_OM_JO_APPROVAL':
      return { ...state, selectedOMJOApproval: action.selectedOMJOApproval }

    case 'GET_OM_JO_DOC':
      return { ...state, selectedOMJODoc: action.selectedOMJODoc }
    case 'GET_OM_JO_ITEMS':
      return { ...state, selectedOMJOItems: action.selectedOMJOItems }
    case 'ADD_NEW_OM_JO_ITEM':
      return {
        ...state,
        selectedOMJOItems: [...state.selectedOMJOItems, action.data]
      }
    case 'UPDATE_OM_JO_ITEM_BILLING_CYCLE':
      return {
        ...state,
        selectedOMJOItems: state.selectedOMJOItems.map(item => (item.id === action.data.id ? {
          ...item,
          omJOItemBillingCycle: action.data.omJOItemBillingCycle,
          omJOItemBillingCycleId: action.data.omJOItemBillingCycleId
        } : item)
        )
      }
    case 'UPDATE_OM_JO_ITEM_UNIT':
      return {
        ...state,
        selectedOMJOItems: state.selectedOMJOItems.map(item => (item.id === action.data.id ? {
          ...item,
          omJOItemUnit: action.data.omJOItemUnit,
          omJOItemUnitId: action.data.omJOItemUnitId
        } : item)
        )
      }
    case 'UPDATE_OM_JO_ITEM_TOTAL':
      return {
        ...state,
        selectedOMJOItems: state.selectedOMJOItems.map(item => (item.id === action.data.id ? {
          ...item,
          omJOItemRate: action.data.omJOItemRate,
          omJOItemQuantity: action.data.omJOItemQuantity,
          omJOItemOtherCost: action.data.omJOItemOtherCost,
          omJOItemTax: action.data.omJOItemTax,
          omJOItemTotalNetCost: action.data.omJOItemTotalNetCost,
          omJOItemTotal: action.data.omJOItemTotal,
          omJOItemGross: action.data.omJOItemGross
        } : item)
        )
      }
    case 'UPDATE_OM_JO_ITEM_OTHER_COST':
      return {
        ...state,
        selectedOMJOItems: state.selectedOMJOItems.map(item => (item.id === action.data.id ? {
          ...item,
          omJOItemOtherCostName: action.data.omJOItemOtherCostName,
          omJOItemOtherCost: action.data.omJOItemOtherCost,
          omJOItemTotal: Number(item.omJOItemTotalNetCost ? item.omJOItemTotalNetCost : 0) + Number(action.data.omJOItemOtherCost ? action.data.omJOItemOtherCost : 0),
          omJOItemGross: Number(item.omJOItemTotalNetCost ? item.omJOItemTotalNetCost : 0) + Number(action.data.omJOItemOtherCost ? action.data.omJOItemOtherCost : 0) + Number(item.omJOItemTax ? item.omJOItemTax : 0)
        } : item)
        )
      }
    case 'UPDATE_OM_JO_ITEM_TAX':
      return {
        ...state,
        selectedOMJOItems: state.selectedOMJOItems.map(item => (item.id === action.data.id ? {
          ...item,
          omJOItemTaxName: action.data.omJOItemTaxName,
          omJOItemTax: Number(item.omJOItemTotal ? item.omJOItemTotal : 0) * (Number(action.data.omJOItemTax) / 100),
          omJOItemTaxType: action.data.omJOItemTaxType,
          omJOItemTaxId: action.data.omJOItemTaxId,
          omJOItemGross: Number(item.omJOItemTotal ? item.omJOItemTotal : 0) + (Number(item.omJOItemTotal ? item.omJOItemTotal : 0) * (Number(action.data.omJOItemTax) / 100))
        } : item)
        )
      }

    case 'GET_OM_EM_VN':
      return { ...state, selectedOMEMVN: action.selectedOMEMVN }

    case 'GET_OM_EM_IMPORT':
      return { ...state, selectedOMEMImport: action.selectedOMEMImport }
    case 'GET_OM_VN_QUOTE_IMPORT':
      return { ...state, selectedOMVNQuoteImport: action.selectedOMVNQuoteImport }
    case 'GET_OM_EM_ITEMS_IMPORT':
      return { ...state, selectedOMJOItems: action.selectedOMEMItemsImport }
    case 'GET_OM_VN_QUOTE_ITEMS_IMPORT':
      return { ...state, selectedOMJOItems: action.selectedOMVNQuoteItemsImport }

    case 'GET_OM_JO_APPROVAL_HISTORY':
      return {
        ...state,
        selectedOMJOApprovalHistory: action.selectedOMJOApprovalHistory.data
      }
    case 'GET_OM_JO_HISTORY':
      return { ...state, OMJOHistory: action.OMJOHistory }

    case 'GET_OM_JO_DOC_HISTORY':
      return {
        ...state,
        selectedOMJODocHistory: action.selectedOMJODocHistory.data
      }
    case 'GET_OM_JO_ITEMS_HISTORY':
      return {
        ...state,
        selectedOMJOItemsHistory: action.selectedOMJOItemsHistory.data
      }
    case 'GET_OM_EM_APPROVAL_HISTORY':
      return {
        ...state,
        selectedOMEMApprovalHistory: action.selectedOMEMApprovalHistory.data
      }
    case 'GET_OM_EM_HISTORY':
      return { ...state, OMEMHistory: action.OMEMHistory }

    case 'GET_OM_EM_DOC_HISTORY':
      return {
        ...state,
        selectedOMEMDocHistory: action.selectedOMEMDocHistory.data
      }
    case 'GET_OM_EM_ITEMS_HISTORY':
      return {
        ...state,
        selectedOMEMItemsHistory: action.selectedOMEMItemsHistory.data
      }

    case 'ORDERMANAGEMENTERROR':
      return { ...state, OrderManagementError: action.data }

    case 'GET_PROJECT_NAMES':
      return { ...state, projectNames: action.projectNames }


    case 'GET_OM_IN_ORDER':
      return { ...state, selectedOMINOrder: action.selectedOMINOrder }
    case 'GET_OM_IN':
      return { ...state, selectedOMIN: action.selectedOMIN }

    case 'GET_OM_IN_DOC':
      return { ...state, selectedOMINDoc: action.selectedOMINDoc }
    case 'GET_OM_IN_ITEMS':
      return { ...state, selectedOMINItems: action.selectedOMINItems }
    case 'GET_OM_IN_ITEM_RECEIPTS':
      return { ...state, selectedOMINItemReceipts: action.selectedOMINItemReceipts }
    case 'GET_OM_IN_ALL_ITEM_RECEIPTS':
      return { ...state, selectedOMINAllItemReceipts: action.selectedOMINAllItemReceipts }
    case 'ADD_NEW_OM_IN_ITEM':
      console.log('action.data', action.data)
      return {
        ...state,
        selectedOMINItems: [...state.selectedOMINItems, action.data]
      }
    case 'UPDATE_OM_IN_ITEM_BILLING_CYCLE':
      return {
        ...state,
        selectedOMINItems: state.selectedOMINItems.map(item => (item.id === action.data.id ? {
          ...item,
          omINItemBillingCycle: action.data.omINItemBillingCycle,
          omINItemBillingCycleId: action.data.omINItemBillingCycleId
        } : item)
        )
      }
    case 'UPDATE_OM_IN_ITEM_UNIT':
      return {
        ...state,
        selectedOMINItems: state.selectedOMINItems.map(item => (item.id === action.data.id ? {
          ...item,
          omINItemUnit: action.data.omINItemUnit,
          omINItemUnitId: action.data.omINItemUnitId
        } : item)
        )
      }
    case 'UPDATE_OM_IN_ITEM_TOTAL':
      return {
        ...state,
        selectedOMINItems: state.selectedOMINItems.map(item => (item.id === action.data.id ? {
          ...item,
          omINItemRate: action.data.omINItemRate,
          omINItemQuantity: action.data.omINItemQuantity,
          omINItemOtherCost: action.data.omINItemOtherCost,
          omINItemTax: action.data.omINItemTax,
          omINItemTotalNetCost: action.data.omINItemTotalNetCost,
          omINItemTotal: action.data.omINItemTotal,
          omINItemGross: action.data.omINItemGross
        } : item)
        )
      }
    case 'UPDATE_OM_IN_ITEM_OTHER_COST':
      return {
        ...state,
        selectedOMINItems: state.selectedOMINItems.map(item => (item.id === action.data.id ? {
          ...item,
          omINItemOtherCostName: action.data.omINItemOtherCostName,
          omINItemOtherCost: action.data.omINItemOtherCost,
          omINItemTotal: Number(item.omINItemTotalNetCost ? item.omINItemTotalNetCost : 0) + Number(action.data.omINItemOtherCost ? action.data.omINItemOtherCost : 0),
          omINItemGross: Number(item.omINItemTotalNetCost ? item.omINItemTotalNetCost : 0) + Number(action.data.omINItemOtherCost ? action.data.omINItemOtherCost : 0) + Number(item.omINItemTax ? item.omINItemTax : 0)
        } : item)
        )
      }
    case 'UPDATE_OM_IN_ITEM_TAX':
      return {
        ...state,
        selectedOMINItems: state.selectedOMINItems.map(item => (item.id === action.data.id ? {
          ...item,
          omINItemTaxName: action.data.omINItemTaxName,
          omINItemTax: Number(item.omINItemTotal ? item.omINItemTotal : 0) * (Number(action.data.omINItemTax) / 100),
          omINItemTaxType: action.data.omINItemTaxType,
          omINItemTaxId: action.data.omINItemTaxId,
          omINItemGross: Number(item.omINItemTotal ? item.omINItemTotal : 0) + (Number(item.omINItemTotal ? item.omINItemTotal : 0) * (Number(action.data.omINItemTax) / 100))
        } : item)
        )
      }
    case 'GET_OM_IN_PF':
      return { ...state, selectedOMINPF: action.selectedOMINPF }
    case 'GET_OM_IN_FN_DOC':
      return { ...state, selectedOMINFNDoc: action.selectedOMINFNDoc }
    case 'GET_OM_IN_BS':
      return { ...state, selectedOMINBS: action.selectedOMINBS }
    case 'ADD_NEW_OM_IN_ITEM_RECEIPT':
      return {
        ...state,
        selectedOMINItemReceipts: [...state.selectedOMINItemReceipts, action.data]
      }

    case 'GET_ALL_OM_PY':
      return { ...state, OMPY: action.OMPY }
    case 'GET_OM_PY':
      return { ...state, selectedOMPY: action.selectedOMPY }
    case 'GET_OM_IN_MODAL':
      const newInvoiceIds = new Set(action?.storePaymentInv?.map(invoice => invoice.job_invoice_id))
      // Filter out invoices with matching job_invoice_ids from existingInvoices
      const filteredExistingInvoices = action?.selectedOMINModal.filter(
        invoice => !newInvoiceIds.has(invoice.job_invoice_id)
      )
      return { ...state, selectedOMINModal: [...filteredExistingInvoices, ...action?.storePaymentInv] }
    case 'GET_OM_PY_IN':
      return { ...state, selectedOMPYIN: action.selectedOMPYIN }
    case 'GET_OM_PY_FINAL_IN':
      return { ...state, selectedOMPYFINALIN: action.selectedOMPYFINALIN }
    case 'UPDATE_OM_PY_SELECTED_IN':

      return {

        ...state,
        selectedOMINModal: state.selectedOMINModal.map(item => (item.job_invoice_id === action.data.job_invoice_id ? {
          ...item,
          omPYPayingAmount: action.data.omPYPayingAmount
        } : item)
        )
      }

    case 'UPDATE_OM_PY_FINAL_SELECTED_IN':

      return {

        ...state,
        selectedOMPYFINALIN: state.selectedOMPYFINALIN.map(item => (item.id === action.data.id ? {
          ...item,
          omPYFinalAmountPaid: action.data.omPYFinalAmountPaid,
          omPYFinalWithholdingAmount: action.data.omPYFinalWithholdingAmount,
          omPYFinalTotalAmountPaid: action.data.omPYFinalTotalAmountPaid
        } : item)
        )
      }
    case 'ATTACH_OM_PY_IN':
      const newInvoiceIds1 = new Set(action.data?.map(invoice => invoice.job_invoice_id))
      // Filter out invoices with matching job_invoice_ids from existingInvoices
      const filteredExistingInvoices1 = state.selectedOMPYIN.filter(
        invoice => !newInvoiceIds1.has(invoice.job_invoice_id)
      )
      return {
        ...state,
        selectedOMPYIN: [...filteredExistingInvoices1, ...action.data]
      }
    case 'GET_OM_PY_APPROVAL':
      return { ...state, selectedOMPYApproval: action.selectedOMPYApproval }
    case 'GET_OM_PY_DOC':
      return { ...state, selectedOMPYDoc: action.selectedOMPYDoc }
    case 'GET_OM_IN_PY':
      return { ...state, selectedOMINPY: action.selectedOMINPY }

    case "CLEAR_ALL":
      return initialState
    default:
      return { ...state }
  }
}
export default OrderManagementReducer