// ** Initial State
const initialState = {
  allData: [],
  allOMData: [],
  allOMLimitedData: [],
  allRoomData: [],
  data: [],
  total: 1,
  params: {},
  selectedClientDepartment: null,
  selectedClientDepartmentDocument: null

}

const ListClientDepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CLIENT_DEPARTMENT':
      return { ...state, allData: action.data }
      case 'GET_ALL_CLIENT_OM_DEPARTMENT':
        return { ...state, allOMData: action.data }
        case 'GET_LIMITED_CLIENT_OM_DEPARTMENT':
          return { ...state, allOMLimitedData: action.data }
        case 'GET_ALL_IN_ASSET_CLIENT_DEPARTMENT':
      return { ...state, allData: action.data }
    case 'GET_ALL_IN_ROOM_CLIENT_DEPARTMENT':
      return { ...state, allRoomData: action.data }
    case 'GET_DATA_CLIENT_DEPARTMENT':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CLIENT_DEPARTMENT':
      return { ...state, selectedClientDepartment: action.selectedClientDepartment }
    case 'GET_CLIENT_DEPARTMENT_DOCUMENT':
      return { ...state, selectedClientDepartmentDocument: action.selectedClientDepartmentDocument }
      
    case 'CLIENTDEPARTMENTERROR':
      return { ...state, clientDepartmentError: action.data }
    default:
      return { ...state }
  }
}
export default ListClientDepartmentReducer