// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedPaymentType: null
  }
  
  const OMPaymentTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_PAYMENT_TYPE':
        return { ...state, allData: action.data }
      case 'GET_DATA_PAYMENT_TYPE':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_PAYMENT_TYPE':
        return { ...state, selectedPaymentType: action.selectedPaymentType }
      case 'PAYMENTTYPEERROR':
        return { ...state, paymentTypeError: action.data }
      default:
        return { ...state }
    }
  }
  export default OMPaymentTypeReducer