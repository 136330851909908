// ** Initial State
const initialState = {
    allData: [],
    allMCData: [],
    data: [],
    total: 1,
    params: {},
    selectedJobMainCategory: null
  }
  const OMJobMainCategoryReducer = (state = initialState, action) => {
    // console.log(action)
    switch (action.type) {
      case 'GET_ALL_JOB_MAIN_CATEGORY':
        return { ...state, allMCData: action.data, allData: action.data }
      case 'GET_ALL_IN_JOB_MAIN_CATEGORY':
        return { ...state, allMCData: action.data, allData: action.data }
      case 'GET_DATA_JOB_MAIN_CATEGORY':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_JOB_MAIN_CATEGORY':
        return { ...state, selectedJobMainCategory: action.selectedJobMainCategory }
      case 'JOBMAINCATEGORYERROR':
        return { ...state, assetMainCategoryError: action.data }
      default:
        return { ...state }
    }
  }
  export default OMJobMainCategoryReducer